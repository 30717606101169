import React, { useState } from 'react';
import { FormGroup, Card, Elevation, Callout, Intent, H5, Button, Collapse } from '@blueprintjs/core';
import { Region } from '../../__generated__/Gds';

interface CutlineRegionsStepProps {
    regions: {
        [cutLineName: string]: {
            [layer: string]: Region[];
        };
    };
}

const CutlineRegionsStep: React.FC<CutlineRegionsStepProps> = ({ regions }) => {
    const [showRegions, setShowRegions] = useState(false);

    // Check if there are no layers with WindowBoundary data in the first cutline
    const firstCutline = Object.values(regions)[0];
    const hasNoLayers = firstCutline && Object.values(firstCutline).length === 0;

    return (
        <div style={{ height: '400px', overflowY: 'auto' }}>
            {/* Warning Callout for saving the process */}
            <Callout intent={Intent.WARNING} style={{ marginBottom: '20px' }}>
                Please note: You will need to save the process to retain the GDS information.
            </Callout>

            {/* Warning Callout if there are no layers in the first cutline */}
            {hasNoLayers ?
                <Callout intent={Intent.DANGER} style={{ marginBottom: '20px' }}>
                    There are no layers with defined regions in the selected cutline. You can either close the dialog or click "Back" to select a different cutline or cell.
                </Callout> :
                <Callout intent={Intent.SUCCESS} style={{ marginBottom: '20px' }}>
                    To use the extracted regions, navigate to the "Simulate" tab in a lithography step, select the "GDS" input type, and choose the desired layer target.
                </Callout>
            }

            <Button
                style={{ marginTop: 'auto' }}
                onClick={() => setShowRegions(!showRegions)}
                icon={showRegions ? "chevron-down" : "chevron-right"}
                minimal
            >
                {showRegions ? "Hide Cutline info" : "Show Cutline info"}
            </Button>
            <Collapse isOpen={showRegions}>
                {Object.entries(regions).map(([cutLineName, layers], cutLineIndex) => (
                    <Card key={cutLineIndex} elevation={Elevation.TWO} style={{ marginBottom: '20px' }}>
                        <H5>Cutline: {cutLineName}</H5>
                        {Object.entries(layers).map(([layerName, layerRegions], layerIndex) => (
                            <FormGroup key={layerIndex} label={`Layer: ${layerName}`}>
                                <p>Total regions in this layer: {layerRegions.length}</p>
                            </FormGroup>
                        ))}
                    </Card>
                ))}
            </Collapse>
        </div>
    );
};

export default CutlineRegionsStep;
