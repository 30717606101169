import { DisplayMap } from "../display-mappings";
import { Tab } from "../enums";
import { DisplayFieldTypes } from "../enums";

export interface LithoProperties {
  patternType: 'Manual' | 'GDS';
  layerPattern: string | null;
  layerInvertPattern: boolean;
  syncPatternType: boolean;

  // SEMIFAB
  patternTypeSemifab: 'Manual' | 'GDS';
  gdsLayer: string | null;
  gdsPattern: string | null;
  gdsInverted: boolean;
}


export const lithographyDisplayMap: DisplayMap = {
  patternType: {
    fieldType: DisplayFieldTypes.PatternFields,
    label: "patternTypeField",
    order: 0,
    tabs: [Tab.DISPLAY, Tab.SEMIFAB],
  },
};

export const lithographyDefaults: LithoProperties = {
  patternType: 'Manual',
  syncPatternType: true,
  layerPattern: "1,2,4,2,1",
  layerInvertPattern: false,
  patternTypeSemifab: 'Manual',
  gdsLayer: null,
  gdsPattern: "1", // Never displayed to the user. Svg Engine uses this if we want to get the pattern from the GDS
  gdsInverted: false,
};

  
