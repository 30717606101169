import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { PatternDialogContent } from "../../dialogs/PatternDialogContent";
import { lithographyDefaults, lithographyDisplayMap, LithoProperties } from "./litho-base";


export interface StepperLitho extends LithoProperties, BaseBlox {
    wavelength: number | null;
    intensity: number | null;
    intensityUnit: Units | null;
    expTime: number | null;
    expTimeUnit: Units | null;
    expDose: number | null;
    expDoseUnit: Units | null;
    jobName: string | null;
    layerID: string | null;
    reticleID: string | null;
    focusOffset: string | null;
    // SEMIFAB
    layerLabelsToExpose: string[] | null;
    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
}

export const stepperLithoDisplay: DisplayMap = {
    wavelength: {
        fieldType: DisplayFieldTypes.Dropdown,
        label: "Lamp wavelength",
        options: ["Select Wavelength","13.5nm (EUV)","193nm (DUV)","247nm (DUV)","365 nm (i-line)", "375nm", "405 nm (h-line)", "435 nm (g-line)"],
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    expDose: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure dose",
        placeholder: "Enter exposure dose",
        isNumber: true,
        units: [Units.MILLIJOULPERSQCM],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    intensity: {
        fieldType: DisplayFieldTypes.Input,
        label: "Lamp intensity",
        placeholder: "Enter lamp intensity",
        isNumber: true,
        units: [Units.MWPERSQCM],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    expTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure time",
        placeholder: "Enter exposure time",
        isNumber: true,
        units: [Units.SEC,Units.MIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    jobName: {
        fieldType: DisplayFieldTypes.Input,
        label: "Job Name",
        placeholder: "Enter job name",
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    layerID: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer ID",
        placeholder: "Enter layer ID",
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },
    reticleID: {
        fieldType: DisplayFieldTypes.Input,
        label: "Reticle ID",
        placeholder: "Enter reticle ID",
        order: 7,
        tabs: [Tab.EXPERIMENTAL],
    },
    focusOffset: {
        fieldType: DisplayFieldTypes.Input,
        label: "Focus Offset",
        placeholder: "Enter focus offset",
        order: 8,
        tabs: [Tab.EXPERIMENTAL],
    },

    // DISPLAY

    // SEMIFAB
    layerLabelsToExpose: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Expose",
        order: 0,
        materialFilter: "ONLY_RESIST",
        tabs: [Tab.SEMIFAB],
    },
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter etch sidewall angle",
        isNumber: true,
        order: 6,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. Use negative values to slope outwards, and positive values to slope inwards."
    },
    ...lithographyDisplayMap
}

export const getStepperLitho = ({stepNumber}: BloxArgs): StepperLitho => ({
        ...lithographyDefaults,
        name: `Stepper Lithography ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.StepperLitho,
        wavelength: null,
        intensity: null,
        intensityUnit: Units.MWPERSQCM,
        expTime: null,
        expTimeUnit: Units.SEC,
        expDose: null,
        expDoseUnit: Units.MILLIJOULPERSQCM,
        jobName: null,
        layerID: null,
        reticleID: null,
        focusOffset: null,
        commentField: null,
        customFields: {},
        //SEMIFAB
        layerLabelsToExpose: [],
        sidewallAngle: 0,
        sidewallAngleUnit: Units.DEG,
    }
);
