import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    Tooltip,
    Checkbox
} from '@blueprintjs/core';
import { useState, isValidElement, useEffect } from 'react';
import { NumericInput } from './NumericInput';
import { StateAndSetter } from '../../hooks/state/parameter-context';
import { IconNames } from '@blueprintjs/icons';
import { LayerSelectivity } from './SelectivityRateInput';
import { Row } from '../../Layout/layouts';

export interface MaterialLegendSelectivity extends LayerSelectivity {
    layer: string;
    selectivity: number | null;
    isSelected: boolean;
}

export interface MaterialLegendVisibilityProps {
    id: string;
    value: MaterialLegendSelectivity[];
    onChange: (value: MaterialLegendSelectivity[]) => void;
    selectedMaterials: string[];
    inputValueDictState: StateAndSetter<{ [key: string]: string }>;
    infoContent?: string | JSX.Element;
}

export const MaterialLegendVisibility: React.FC<MaterialLegendVisibilityProps> = ({
    id,
    value,
    onChange,
    infoContent,
    selectedMaterials,
    inputValueDictState
}) => {

    useEffect(() => {
        const isOutOfSync = selectedMaterials.some(
            material => !value.find(layer => layer.layer === material)
        ) || value.some(
            layer => !selectedMaterials.includes(layer.layer)
        );
    
        if (isOutOfSync) {
            const newValue = selectedMaterials.map(material => {
                const existingLayer = value.find(layer => layer.layer === material);
                return existingLayer
                    ? existingLayer
                    : { layer: material, selectivity: 1, isSelected: true };
            });
            onChange(newValue);
        }
    }, [selectedMaterials, value, onChange]);
    

    const handleInputChange = (material: string, selectivity: string, isSelected?: boolean) => {
        const updatedSelectivity = selectivity === "" ? null : parseFloat(selectivity);
        const updatedLayers = value.map(layer =>
            layer.layer === material
                ? { ...layer, selectivity: updatedSelectivity, isSelected: isSelected ?? layer.isSelected }
                : layer
        );

        if (!value.some(layer => layer.layer === material)) {
            updatedLayers.push({ layer: material, selectivity: updatedSelectivity, isSelected: isSelected ?? true });
        }
        onChange(updatedLayers);
    };

    const handleCheckboxChange = (material: string, isSelected: boolean) => {
        const updatedLayers = value.map(layer =>
            layer.layer === material ? { ...layer, isSelected } : layer
        );
        onChange(updatedLayers);
    };

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const toggleDialog = () => setIsDialogOpen(prev => !prev);

    let infoRender: JSX.Element = <></>;
    if (typeof infoContent === 'string') {
        infoRender = (
            <Tooltip content={<div style={{ width: '400px', whiteSpace: 'normal' }}>{infoContent}</div>}>
                <Button minimal icon={IconNames.INFO_SIGN} className={Classes.INPUT_ACTION} />
            </Tooltip>
        );
    } else if (isValidElement(infoContent)) {
        infoRender = (
            <>
                <Button
                    minimal
                    icon={IconNames.INFO_SIGN}
                    className={Classes.INPUT_ACTION}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleDialog();
                    }}
                />
                <Dialog
                    isOpen={isDialogOpen}
                    onClose={toggleDialog}
                    title="Information"
                >
                    {infoContent}
                </Dialog>
            </>
        );
    }

    const labelWithInfoContent = (label: string) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
            <span>{label}</span>
            {infoRender}
        </div>
    );
    
    return (
        <FormGroup label={labelWithInfoContent('Relative Depth | Show')}>
            {selectedMaterials.map((material, index) => {
                const layer = value.find(layer => layer.layer === material);
                return (
                    <FormGroup
                        style={{ justifyContent: 'right', alignItems: 'center', height: '100%' }}
                        key={index}
                        inline
                        label={`${material}:`}
                    >
                        <div style={{ marginLeft: 'auto', width: '100px' }}>
                            <Row id={`${material}-legend-visibility-input`}><NumericInput
                                inputStyle={{ textAlign: 'right' }}
                                defaultValue='1'
                                id={`${id}-${material}`}
                                onChange={(value) => handleInputChange(material, value)}
                                inputValueDictState={inputValueDictState}
                                inputGroupProps={{}}
                            />
                            <Tooltip content="Toggle dopant visibility">
                                <Checkbox
                                    checked={layer ? layer.isSelected : true}
                                    onChange={(e) => handleCheckboxChange(material, e.target.checked)}
                                    style={{ marginLeft: '10px' }}
                                />
                            </Tooltip>
                            </Row>
                        </div>
                    </FormGroup>
                );
            })}
        </FormGroup>
    );
};
