import React, { useState, useCallback } from 'react';

interface DialogProps {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    content?: string | React.JSX.Element;
    title?: string;
}

export function useConfirmDialog(
    DialogComponent: React.ComponentType<DialogProps>
) {
    const [isOpen, setIsOpen] = useState(false);
    const [dialogConfig, setDialogConfig] = useState<Partial<DialogProps>>({});
    const [promiseResolver, setPromiseResolver] = useState<
        ((value: boolean) => void) | null
    >(null);

    const showConfirmDialog = useCallback(
        (config: Partial<DialogProps> = {}): Promise<boolean> => {
            return new Promise((resolve) => {
                setDialogConfig(config);
                setPromiseResolver(() => resolve);
                setIsOpen(true);
            });
        },
        []
    );

    const handleConfirm = useCallback(() => {
        if (promiseResolver) {
            promiseResolver(true);
            setIsOpen(false);
            setPromiseResolver(null);
        }
    }, [promiseResolver]);

    const handleCancel = useCallback(() => {
        if (promiseResolver) {
            promiseResolver(false);
            setIsOpen(false);
            setPromiseResolver(null);
        }
    }, [promiseResolver]);

    const ConfirmDialogElement = isOpen ? (
        <DialogComponent
            isOpen={isOpen}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            {...dialogConfig} // Merge runtime configuration
        />
    ) : null;

    return { showConfirmDialog, ConfirmDialogElement };
}
