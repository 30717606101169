import React from 'react';
import UploadFileStep from './UploadFileStep';
import DefineParametersStep from './DefineParametersStep';
import { GdsInfo } from '../../__generated__/Gds';

interface UploadAndDefineParametersStepProps {
    // Props from UploadFileStep
    cellsExtracted: boolean;
    selectedFile: File | null;
    setSelectedFile: (file: File | null) => void;
    isFileUploaded: boolean;
    setIsFileUploaded: (uploaded: boolean) => void;
    onUploadSuccess: (filePath: string) => void;
    setError: (error: string | null) => void;
    // Props from DefineParametersStep
    selectedCell: string | null;
    setSelectedCell: (cell: string | null) => void;
    cutlineName: string | null;
    setCutlineName: (name: string | null) => void;
    x1: number | null;
    setX1: (x: number | null) => void;
    y1: number | null;
    setY1: (y: number | null) => void;
    x2: number | null;
    setX2: (x: number | null) => void;
    y2: number | null;
    setY2: (y: number | null) => void;
    cells: string[];
    cellsLoading: boolean;
    filePath: string | null;
    savedGdsInfo?: GdsInfo;
}

const UploadAndDefineParametersStep: React.FC<UploadAndDefineParametersStepProps> = (props) => {
    const {cellsLoading, selectedFile, isFileUploaded} = props;

    const disableParameterSelection = cellsLoading || !!(selectedFile && !isFileUploaded);

    return (
        <div>
            <UploadFileStep
                cellsExtracted={props.cellsExtracted}
                selectedFile={props.selectedFile}
                setSelectedFile={props.setSelectedFile}
                setIsFileUploaded={props.setIsFileUploaded}
                onUploadSuccess={props.onUploadSuccess}
                setError={props.setError}
            />
            <DefineParametersStep
                cells={props.cells}
                selectedCell={props.selectedCell}
                setSelectedCell={props.setSelectedCell}
                cutlineName={props.cutlineName}
                setCutlineName={props.setCutlineName}
                x1={props.x1}
                setX1={props.setX1}
                y1={props.y1}
                setY1={props.setY1}
                x2={props.x2}
                setX2={props.setX2}
                y2={props.y2}
                setY2={props.setY2}
                disableParameterSelection={disableParameterSelection}
                savedGdsInfo={props.savedGdsInfo}
                selectedFile={props.selectedFile} 
            />
        </div>
    );
};

export default UploadAndDefineParametersStep;
