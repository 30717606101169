import { Intent } from "@blueprintjs/core";
import { useMutation } from "react-query";
import { showToast } from "../..";
import { AllBloxes } from "../../Data/BloxSchema/base-blox";
import { SEMIFAB_API_URL } from "../../utils/constants";
import { useAuth0 } from "@auth0/auth0-react";
import { GdsInfo } from "../../__generated__/Gds";

interface ProcessBloxRequest {
    bloxesInfo: AllBloxes[];
    processId: string;
    gdsInfo: GdsInfo | null;
}

export interface ProcessBloxResponse {
    svgs: { [key: string]: string };
    legend: string | null;
    semifabErrors: string | null;

}

async function fetchSemifabJSON(data: ProcessBloxRequest, getAccessTokenSilently: () => Promise<string>): Promise<ProcessBloxResponse> {
    const token = await getAccessTokenSilently();

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };

    const response = await fetch(`${SEMIFAB_API_URL}/Semifab/ProcessBloxJSON`, requestOptions);

    if (!response.ok) {
        throw new Error('Process failed');
    }
    return response.json();
}

export function useFetchSemifabJSON() {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation(
        (bloxesInfo: ProcessBloxRequest) => fetchSemifabJSON(bloxesInfo, getAccessTokenSilently),
        {
            onError: (error: any) => {
                showToast({
                    message: error instanceof Error ? error.message : "An error occurred",
                    intent: Intent.DANGER,
                    timeout: 3000
                });
            },
        }
    );
}