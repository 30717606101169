import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { lithographyDefaults, lithographyDisplayMap, LithoProperties } from "./litho-base";

export interface EbeamLitho extends LithoProperties, BaseBlox {
    //EXPERIMENTAL
    accelVoltage: number | null;
    accelVoltageUnit: Units | null;
    beamCurrent: number | null;
    beamCurrentUnit: Units | null;
    exposureDose: number | null;
    exposureDoseUnit: Units | null;
    dwellTime: number | null;
    dwellTimeUnit: Units | null;
    // DISPLAY

    // SEMIFAB
    layerLabelsToExpose: string[] | null;
    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
}

export const ebeamLithoDisplay: DisplayMap = {
    accelVoltage: {
        fieldType: DisplayFieldTypes.Input,
        label: "Accel. Voltage / Beam Energy",
        placeholder: "Enter voltage/energy",
        isNumber: true,
        units: [Units.KILOVOLT,Units.KILOELECVOLT],
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    exposureDose: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure Dose",
        placeholder: "Enter exposure dose",
        isNumber: true,
        units: [Units.UCOULPERSQCM],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    beamCurrent: {
        fieldType: DisplayFieldTypes.Input,
        label: "Beam Current",
        placeholder: "Enter beam current",
        isNumber: true,
        units: [Units.PICOAMP,Units.NANOAMP,Units.MICROAMP],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    dwellTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Dwell Time",
        placeholder: "Enter dwell time",
        isNumber: true,
        units: [Units.NANOSEC],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },

    
    // DISPLAY

    // SEMIFAB
    layerLabelsToExpose: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Expose",
        order: 5,
        materialFilter: "ONLY_RESIST",
        tabs: [Tab.SEMIFAB],
    },
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter etch sidewall angle",
        isNumber: true,
        order: 5,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. Use negative values to slope outwards, and positive values to slope inwards."
    },
    ...lithographyDisplayMap,
}

export const getEbeamLitho = ({stepNumber}: BloxArgs): EbeamLitho => ({
        name: `Electron-Beam Lithography ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.EbeamLitho,
        accelVoltage: null,
        accelVoltageUnit: Units.KILOVOLT,
        exposureDose: null,
        exposureDoseUnit: Units.UCOULPERSQCM,
        beamCurrent: null,
        beamCurrentUnit: Units.NANOAMP,
        dwellTime: null,
        dwellTimeUnit: Units.NANOSEC,
        commentField: null,
        customFields: {},
        //SEMIFAB
        layerLabelsToExpose: [],
        sidewallAngle: 0,
        sidewallAngleUnit: Units.DEG,
        ...lithographyDefaults
});
