export class UnionFind {
    private parent: number[];
    private rank: number[];

    constructor(size: number) {
        this.parent = new Array(size);
        this.rank = new Array(size);
        
        // Initially, each element is its own parent (self-rooted tree)
        // and the rank is 0 (flat tree)
        for (let i = 0; i < size; i++) {
            this.parent[i] = i;
            this.rank[i] = 0;
        }
    }

    // Find the root of an element with path compression
    find(x: number): number {
        if (this.parent[x] !== x) {
            // Recursively find the root and compress the path
            this.parent[x] = this.find(this.parent[x]);
        }
        return this.parent[x];
    }

    // Union two sets by rank
    union(x: number, y: number): void {
        const rootX = this.find(x);
        const rootY = this.find(y);

        if (rootX !== rootY) {
            // Union by rank
            if (this.rank[rootX] > this.rank[rootY]) {
                this.parent[rootY] = rootX;
            } else if (this.rank[rootX] < this.rank[rootY]) {
                this.parent[rootX] = rootY;
            } else {
                // If ranks are the same, pick one as the new root and increment its rank
                this.parent[rootY] = rootX;
                this.rank[rootX]++;
            }
        }
    }

    // Check if two elements are in the same set
    isConnected(x: number, y: number): boolean {
        return this.find(x) === this.find(y);
    }
}
