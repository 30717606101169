import React, { useEffect, useState } from 'react';
import { DialogBody, FileInput, Button, Intent, Callout, FormGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useGenerateUploadUrl } from '../../hooks/DataFetching/use-fetch-storage';
import { useParams } from 'react-router-dom';
import { Loading } from '../../components/Loading';

interface UploadFileStepProps {
    cellsExtracted: boolean;
    selectedFile: File | null;
    setSelectedFile: (file: File | null) => void;
    setIsFileUploaded: (uploaded: boolean) => void;
    onUploadSuccess: (filePath: string) => void;
    setError: (error: string | null) => void;
}

const UploadFileStep: React.FC<UploadFileStepProps> = ({
    cellsExtracted,
    selectedFile,
    setSelectedFile,
    setIsFileUploaded,
    onUploadSuccess,
    setError,
}) => {
    const { processId } = useParams<{ processId: string }>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [fileSizeMsg, setFileSizeMsg] = useState<string | null>(null);

    const { mutate: generateUploadUrl } = useGenerateUploadUrl();

    useEffect(() => {
        if (cellsExtracted) {
            setIsLoading(false);
        }
    }, [cellsExtracted]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setSelectedFile(e.target.files[0]);
            setIsFileUploaded(false);
            setIsSuccess(false); // Reset success state on new file selection
        }
    };

    const handleUploadAndFetchCells = async () => {
        if (!selectedFile) return;

        setIsLoading(true);
        setError(null);
        setIsSuccess(false); // Reset success state before upload

        try {
            generateUploadUrl(
                { processId },
                {
                    onSuccess: (data) => {
                        setError(null);
                        const { url, fields } = data;
                        const formData = new FormData();
                        Object.entries(fields).forEach(([key, value]) => {
                            formData.append(key, value as string);
                        });
                        formData.append('file', selectedFile);

                        fetch(url, {
                            method: 'POST',
                            body: formData,
                        })
                            .then((response) => {
                                if (!response.ok) {
                                    response.text().then((text) => {
                                        // Check for specific 'EntityTooLarge' error
                                        if (text.includes("<Code>EntityTooLarge</Code>")) {
                                            setFileSizeMsg("File size exceeds the 50 MB limit.");
                                        } else {
                                            setError(`File upload failed with status: ${response.status}`);
                                        }
                                    });
                                }
                                if (!response.ok) {
                                    throw new Error(`File upload failed with status: ${response.status}`);
                                }
                                onUploadSuccess(fields.key);
                                setIsFileUploaded(true);
                                setIsSuccess(true);
                            })
                            .catch((err) => {
                                setError(`Failed to upload file: ${err.message}`);
                                setIsLoading(false);
                            });
                    },
                    onError: (err: Error) => {
                        setError(`Failed to generate upload URL: ${err.message}`);
                        setIsLoading(false);
                    },
                }
            );
        } catch (err) {
            setError(`Failed to upload file: ${(err as Error)?.message}`);
            setIsLoading(false);
        }
    };

    const isUploadButtonDisabled = !selectedFile || isLoading;

    return (
        <DialogBody>
            <div>
                <FileInput
                    text={selectedFile ? selectedFile.name : 'Upload new GDS file...'}
                    onInputChange={handleFileChange}
                    disabled={isLoading}
                    large
                    style={{ width: '100%' }}
                    inputProps={{ accept: '.gds' }}
                />
            </div>

            <div style={{ marginTop: '20px' }}>
                <FormGroup
                    helperText={fileSizeMsg ? fileSizeMsg : undefined}
                    intent={fileSizeMsg ? Intent.DANGER : Intent.NONE}
                >
                    <Button
                        intent={Intent.PRIMARY}
                        text="Upload & Fetch Cells"
                        onClick={handleUploadAndFetchCells}
                        disabled={isUploadButtonDisabled}
                        icon={IconNames.UPLOAD}
                        fill
                    />
                </FormGroup>
            </div>

            {isLoading && (
                <div style={{ marginTop: '20px' }}>
                    <Loading />
                </div>
            )}

            {/* Success Callout */}
            {isSuccess && cellsExtracted && (
                <Callout intent={Intent.SUCCESS} icon="tick">
                    <div>
                        Uploaded file and extracted cells.
                    </div>
                    <div>
                        Current File: <strong>{selectedFile?.name ?? 'Unknown File'}</strong>
                    </div>
                </Callout>

            )}
        </DialogBody>
    );
};

export default UploadFileStep;
