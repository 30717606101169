import React from 'react';
import PatternFields from './PatternFields';
import { LithoProperties } from '../../Data/BloxSchema/litho-base';
import { StateAndSetter } from '../../hooks/state/parameter-context';

interface LithoExtendedBlox extends LithoProperties {
  [key: string]: any;
}

interface SemifabPatternFieldsProps {
  lithoBlox: LithoExtendedBlox;
  processGdsLayers: string[];
  inputValueDictState: StateAndSetter<{ [key: string]: string }>;
  onChange: (field?: string, value?: any, updateProperties?: { property: string; value: any }[]) => void;
  readOnly?: boolean;
  baseId: string;
}

const SemifabPatternFields: React.FC<SemifabPatternFieldsProps> = ({
  lithoBlox,
  processGdsLayers,
  inputValueDictState,
  onChange,
  readOnly,
  baseId,
}) => {
  return (
    <PatternFields
      lithoBlox={lithoBlox}
      processGdsLayers={processGdsLayers}
      inputValueDictState={inputValueDictState}
      onChange={onChange}
      readOnly={readOnly}
      baseId={baseId}
      patternTypeProps={{
        key: 'patternTypeSemifab',
        label: 'Pattern Type for Simulation',
        value: lithoBlox.patternTypeSemifab,
      }}
    />
  );
};

export default SemifabPatternFields;
