import React, { useCallback } from 'react';
import { LithoProperties } from '../../Data/BloxSchema/litho-base';
import { CsvInput } from './CsvInput';
import { HTMLSelect, Label, Switch } from '@blueprintjs/core';
import { useFabuState } from '../../hooks/state/use-fabu-state';
import { updateGdsPattern } from '../../utils/gds/update-gds-pattern';
import { StateAndSetter } from '../../hooks/state/parameter-context';
import { PatternDialogContent } from '../../dialogs/PatternDialogContent';
import { LabeledSwitch } from './LabeledSwitch';

interface LithoExtendedBlox extends LithoProperties {
  [key: string]: any;
}

interface PatternTypeProps {
  key: string;
  label: string;
  value: string;
}

interface PatternFieldsProps {
  lithoBlox: LithoExtendedBlox;
  processGdsLayers: string[];
  inputValueDictState: StateAndSetter<{ [key: string]: string }>;
  onChange: (field?: string, value?: any, updateProperties?: { property: string; value: any }[]) => void;
  readOnly?: boolean;
  baseId: string;
  patternTypeProps: PatternTypeProps; // Single prop for pattern type
}

const PatternFields: React.FC<PatternFieldsProps> = ({
  lithoBlox: properties,
  processGdsLayers,
  inputValueDictState,
  onChange,
  readOnly,
  baseId,
  patternTypeProps,
}) => {
  const [processBloxes, setProcessBloxes] = useFabuState('processBloxes');
  const [selectedBloxId] = useFabuState('selectedBloxIdState');
  const [processGdsInfo] = useFabuState('processGdsInfo');

  const selectedBloxIdx = processBloxes.findIndex((blox) => blox.id === selectedBloxId);

  // Define IDs
  const syncPatternId = `${baseId}-syncPattern`;
  const patternTypeId = `${baseId}-${patternTypeProps.key}`;
  const layerPatternId = `${baseId}-layerPattern`;
  const layerInvertPatternId = `${baseId}-layerInvertPattern`;
  const gdsLayerId = `${baseId}-gdsLayer`;
  const gdsInvertedId = `${baseId}-gdsInverted`;

  // Shared definitions for layerPattern and layerInvertPattern
  const layerPatternLabel = 'Define Pattern';
  const layerPatternValue = properties.layerPattern;
  const layerInvertPatternLabel = 'Toggle start region exposure';
  const layerInvertPatternValue = properties.layerInvertPattern;
  const syncPatternValue = properties.syncPatternType;

  const patternTypeChange = useCallback((value: string) => {
    if (properties.syncPatternType) {
      onChange(undefined, undefined, [{ property: 'patternType', value }, { property: 'patternTypeSemifab', value }]);
    } else {
      onChange(patternTypeProps.key, value);
    }
  }, [onChange, patternTypeProps, properties]);

  const syncPatternTypeChange = useCallback(() => {
    const { patternType, patternTypeSemifab, syncPatternType } = properties;
    const isOutOfSync = patternType !== patternTypeSemifab;
    const updatedSyncState = !syncPatternType;

    // Determine the key to synchronize and its target value
    const isPatternTypeKey = patternTypeProps.key === 'patternType';
    const keyToSync = isPatternTypeKey ? 'patternTypeSemifab' : 'patternType';
    const targetValue = isPatternTypeKey ? patternType : patternTypeSemifab;

    if (isOutOfSync && updatedSyncState) {
      // Synchronize properties if they are out of sync and sync state is toggled on
      const updates = [
        { property: keyToSync, value: targetValue },
        { property: 'syncPatternType', value: true },
      ];
      onChange(undefined, undefined, updates);
    } else {
      // Toggle the sync state without additional synchronization
      onChange('syncPatternType', updatedSyncState);
    }
  }, [onChange, patternTypeProps.key, properties]);

  return (
    <>
      <LabeledSwitch
        key={syncPatternId}
        label={'Sync Simulator and Display Pattern Type'}
        disabled={readOnly}
        onChange={syncPatternTypeChange}
        checked={syncPatternValue}
      />
      {/* Pattern Type Dropdown */}
      <Label>
        {patternTypeProps.label}
        <HTMLSelect
          value={patternTypeProps.value}
          onChange={(event) => patternTypeChange(event.target.value)}
          disabled={readOnly}
          id={patternTypeId}
          options={['Manual', 'GDS']}
        />
      </Label>

      {/* Conditional fields based on patternType */}
      {patternTypeProps.value === 'Manual' && (
        <>
          <CsvInput
            label={layerPatternLabel}
            onChange={(val) => onChange('layerPattern', val)}
            disabled={readOnly}
            infoContent={PatternDialogContent}
            inputValueDictState={inputValueDictState}
            inputGroupProps={{
              value: layerPatternValue ?? '',
            }}
            id={layerPatternId}
          />
          <Switch
            label={layerInvertPatternLabel}
            checked={layerInvertPatternValue}
            onChange={() => onChange('layerInvertPattern', !layerInvertPatternValue)}
            disabled={readOnly}
            id={layerInvertPatternId}
          />
        </>
      )}

      {patternTypeProps.value === 'GDS' && (
        <>
          <Label className="rightPanelLabel">
            Layer from GDS
            <HTMLSelect
              value={properties.gdsLayer || 'Select Layer or Upload GDS'}
              disabled={readOnly}
              options={['Select Layer or Upload GDS', ...processGdsLayers]}
              onChange={(event) => {
                const selectedLayer = event.target.value;
                if (processGdsInfo && selectedLayer !== 'Select Layer or Upload GDS') {
                  updateGdsPattern(
                    processGdsInfo,
                    processBloxes,
                    selectedBloxIdx,
                    selectedLayer,
                    setProcessBloxes
                  );
                } else {
                  onChange('gdsLayer', null);
                }
              }}
              id={gdsLayerId}
            />
          </Label>
          <Switch
            label="Invert GDS pattern"
            checked={properties.gdsInverted}
            onChange={() => onChange('gdsInverted', !properties.gdsInverted)}
            disabled={readOnly}
            id={gdsInvertedId}
          />
        </>
      )}
    </>
  );
};

export default PatternFields;
