import React, { useMemo, useState } from 'react';
import { DialogBody, HTMLSelect, FormGroup, InputGroup, Intent, Callout } from '@blueprintjs/core';
import { GdsInfo } from '../../__generated__/Gds';
import { IconNames } from '@blueprintjs/icons';
import { Units } from '../../Data/enums';

interface DefineParametersStepProps {
    cells: string[];
    selectedCell: string | null;
    setSelectedCell: (cell: string | null) => void;
    cutlineName: string | null;
    setCutlineName: (name: string | null) => void;
    x1: number | null;
    setX1: (x: number | null) => void;
    y1: number | null;
    setY1: (y: number | null) => void;
    x2: number | null;
    setX2: (x: number | null) => void;
    y2: number | null;
    setY2: (y: number | null) => void;
    disableParameterSelection: boolean;
    savedGdsInfo?: GdsInfo;
    selectedFile: File | null;
}

const DefineParametersStep: React.FC<DefineParametersStepProps> = ({
    cells,
    selectedCell,
    setSelectedCell,
    cutlineName,
    setCutlineName,
    x1,
    setX1,
    y1,
    setY1,
    x2,
    setX2,
    y2,
    setY2,
    disableParameterSelection,
    savedGdsInfo,
    selectedFile,
}) => {
    const sortedCells = cells.sort();

    const [x1Input, setX1Input] = useState(x1 !== null ? x1.toString() : '');
    const [y1Input, setY1Input] = useState(y1 !== null ? y1.toString() : '');
    const [x2Input, setX2Input] = useState(x2 !== null ? x2.toString() : '');
    const [y2Input, setY2Input] = useState(y2 !== null ? y2.toString() : '');

    const cutlineLengthMsg = useMemo(() => {
        if (x1 !== null && y1 !== null && x2 !== null && y2 !== null) {
            const cutlineLength = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
            return <>The cutline length is approximately <strong>{cutlineLength.toFixed(3)} {Units.MICRON}</strong>.</>
        }
        return `All dimensions are processed in micrometers (${Units.MICRON}).`
    }, [x1, y1, x2, y2]);


    const handleCoordinateChange = (
        value: string,
        setInput: React.Dispatch<React.SetStateAction<string>>,
        setCoordinate: (coordinate: number | null) => void
    ) => {
        const validNumberPattern = /^-?(\d+)?(\.\d*)?$/;
        if (validNumberPattern.test(value)) {
            setInput(value);
        }
        const parsedValue = Number(value);
        if (!isNaN(parsedValue) && value !== '' && value !== '-' && value !== '.') {
            setCoordinate(parsedValue);
        } else {
            setCoordinate(null);
        }
    };

    const useSavedGdsInfo = !!savedGdsInfo && !selectedFile;

    const savedCellNames = savedGdsInfo?.cellNames ?? [];
    const cellOptions = useSavedGdsInfo ? savedCellNames : sortedCells;

    return (
        <DialogBody>
            <>
                {useSavedGdsInfo && (
                    <>
                        <Callout style={{marginBottom: '8px'}} intent={Intent.SUCCESS} icon={IconNames.Tick}>
                        <div>
                            Current File: <strong>{savedGdsInfo?.userFileName ?? 'Unknown File'}</strong>
                        </div>
                    </Callout>
                    </>
                )}

                <FormGroup label="Select Cell (Required)">
                    <HTMLSelect
                        options={['Select cell', ...cellOptions]}
                        onChange={(e) => setSelectedCell(e.target.value)}
                        value={selectedCell ?? 'Select cell'}
                        fill
                        disabled={disableParameterSelection}
                    />
                </FormGroup>

                <FormGroup label="Cutline Name (Optional)">
                    <InputGroup
                        placeholder="Enter cutline name"
                        value={cutlineName ?? ''}
                        onChange={(e) => setCutlineName(e.target.value)}
                        disabled={disableParameterSelection}
                    />
                </FormGroup>

                <FormGroup label="Start Coordinates (Required)">
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <InputGroup
                            placeholder="Enter X1"
                            value={x1Input}
                            type="text"
                            onChange={(e) => handleCoordinateChange(e.target.value, setX1Input, setX1)}
                            required
                            disabled={disableParameterSelection}
                        />
                        <InputGroup
                            placeholder="Enter Y1"
                            value={y1Input}
                            type="text"
                            onChange={(e) => handleCoordinateChange(e.target.value, setY1Input, setY1)}
                            required
                            disabled={disableParameterSelection}
                        />
                    </div>
                </FormGroup>

                <FormGroup label="End Coordinates (Required)">
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <InputGroup
                            placeholder="Enter X2"
                            value={x2Input}
                            type="text"
                            onChange={(e) => handleCoordinateChange(e.target.value, setX2Input, setX2)}
                            required
                            disabled={disableParameterSelection}
                        />
                        <InputGroup
                            placeholder="Enter Y2"
                            value={y2Input}
                            type="text"
                            onChange={(e) => handleCoordinateChange(e.target.value, setY2Input, setY2)}
                            required
                            disabled={disableParameterSelection}
                        />
                    </div>
                </FormGroup>
                <Callout intent={Intent.PRIMARY} style={{ marginTop: '16px' }}> {cutlineLengthMsg} </Callout>
                <br/>
                <p>Click 'Next' to process cutline regions.</p>
            </>
        </DialogBody>
    );
};

export default DefineParametersStep;
