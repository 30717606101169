import React from 'react';
import { Dialog, Button, Intent, Classes } from '@blueprintjs/core';

interface ConfirmDialogProps {
    isOpen: boolean;
    content?: string | React.JSX.Element;
    onConfirm: () => void;
    onCancel: () => void;
    title?: string;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    isOpen,
    content,
    onConfirm,
    onCancel,
    title = "Confirm Action",
}) => {
    return (
        <Dialog
            isOpen={isOpen}
            title={title}
            onClose={onCancel}
            canOutsideClickClose={false}
            style={{ width: '600px' }}
        >
            <div className={Classes.DIALOG_BODY}>
                {content && (
                    <div style={{ marginBottom: '16px' }}>
                        {typeof content === 'string' ? <p>{content}</p> : content}
                    </div>
                )}
                <p>Do you want to continue?</p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={onCancel} text="Cancel" />
                    <Button intent={Intent.PRIMARY} onClick={onConfirm} text="Continue" />
                </div>
            </div>
        </Dialog>
    );
};
