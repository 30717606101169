import { GdsInfo } from "../../__generated__/Gds";
import { AllBloxes } from "../../Data/BloxSchema/base-blox";
import { calculateEuclideanDistance, mapGdsRegionsToPattern } from "./map-gds-bounds";

// Helper function to update a single blox
export const getUpdatedBlox = (
    blox: AllBloxes,
    gdsLayer: string | null,
    processGdsInfo: GdsInfo
): AllBloxes => {
    if (!gdsLayer) return { ...blox, gdsPattern: "1", gdsLayer: null };

    const firstCutlineKey = Object.keys(processGdsInfo.regionsMap)[0];
    const totalLength = calculateEuclideanDistance(processGdsInfo.cutLines[firstCutlineKey]);
    const regions = processGdsInfo.regionsMap[firstCutlineKey].layers[gdsLayer];
    const newPatternString = mapGdsRegionsToPattern(totalLength, regions);

    return { ...blox, gdsPattern: newPatternString, gdsLayer };
};

// Main function to update the processBloxes array
export const updateGdsPattern = (
    processGdsInfo: GdsInfo, 
    processBloxes: AllBloxes[], 
    bloxIdx: number,
    gdsLayer: string | null,
    setProcessBloxes?: React.Dispatch<React.SetStateAction<AllBloxes[]>>
) => {
    const updatedBlox = getUpdatedBlox(processBloxes[bloxIdx], gdsLayer, processGdsInfo);
    const updatedBloxes = [...processBloxes];
    updatedBloxes[bloxIdx] = updatedBlox;

    // If setProcessBloxes is provided, update the state; otherwise, return the updated array
    if (setProcessBloxes) {
        setProcessBloxes(updatedBloxes);
    }

    return updatedBloxes;
};
