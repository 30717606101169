import { getNewBlox } from "../hooks/new-blox-data";
import { AllBloxes } from "./BloxSchema/base-blox";

export function getBloxWithDefaults(blox: {[key: string]: any}) {
    const defaultBlox = getNewBlox(blox.bloxType, 0);
    const safeBlox: {[key: string]: any} = {};
    const allKeys = new Set([...Object.keys(defaultBlox), ...Object.keys(blox)]);

    for (const key of allKeys) {
        if (blox[key] === undefined || blox[key] === null) {
            safeBlox[key] = defaultBlox[key as keyof AllBloxes];
        } else {
            safeBlox[key] = blox[key];
        }
    }

    return safeBlox as AllBloxes;
}

export function getBloxArrayWithDefaults(bloxes: AllBloxes[]) {
    return bloxes.map(blox => getBloxWithDefaults(blox)) as AllBloxes[];
}
