import { Row } from "../../Layout/layouts";
import { ProcessBlox } from "./../ProcessBlox";
import { AllBloxes, LayerProperties } from "../../Data/BloxSchema/base-blox";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { useAuth0 } from '@auth0/auth0-react';
import { BASE_FABUBLOX_API_URL } from "../../utils/constants";
import { useProcessHandlers } from "./../hooks/use-process-handlers";
import { Loading } from "../Loading";
import { v4 as uuidv4 } from 'uuid'
import { SvgServiceContext } from "../../hooks/state/svg-service-provider";
import { SVGDisplayMode } from "../../Services/SVGEngine";
import { ProcessSettingsContext } from "../../hooks/state/process-settings-provider";
import { UnsavedChangesContext } from "../../hooks/state/unsaved-changes-provider";
import { AdditionalOwnerUser, Section } from "../../__generated__/Process";
import { useSectionManager } from "../hooks/use-section-manager";
import { BetweenBlox } from "../BetweenBlox";
import { SectionHeader, scrollToSection } from "../SectionHeader";
import { showToast } from "../..";
import { useClearProcess } from "../hooks/use-clear-process";
import { StateAndSetter } from "../../hooks/state/parameter-context";
import { ModuleInsertItem } from "../ModuleDraggable";
import { Units } from "../../Data/enums";
import { getNewBlox } from "../../hooks/new-blox-data";
import { getBloxArrayWithDefaults } from "../../Data/fill-blox-defaults";

export interface ProcessEditorProps {
  closedSectionState: StateAndSetter<{[key: string]: boolean}>;
}

export default function ProcessEditor(props: ProcessEditorProps) {
  const { closedSectionState } = props;
  const [closedSections, setClosedSections] = closedSectionState;
  const { processId } = useParams<{processId: string}>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const signature = queryParams.get('signature');
  const match = useRouteMatch();

  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const copiedBloxState = useState<AllBloxes | null>(null);
  const clearProcess = useClearProcess();
  
  const { isStack3D, isStackHidden } = useContext(ProcessSettingsContext);
  const [ processBloxes, setProcessBloxes] = useFabuState('processBloxes');
  const [processSections,setProcessSections] = useFabuState('processSections');
  const [,setProcessName] = useFabuState('processName');
  const [,setProcessUsername] = useFabuState('processUsername');
  const [,setProcessId] = useFabuState('processId');
  const [,setProcessIsPrivate] = useFabuState('processIsPrivate');
  const [,setProcessAdditionalOwners] = useFabuState('processAdditionalOwners');
  const [,setProcessDesc] = useFabuState('processDesc');
  const [,setProcessReference] = useFabuState('processReference');
  const [,setProcessGdsInfo] = useFabuState('processGdsInfo');

  const [selectedBloxId, ] = useFabuState('selectedBloxIdState');
  const [,setProcessIsReadOnly] = useFabuState('processIsReadOnly');
  const [,setProcessGroups] = useFabuState('processGroups');
  const { setEditorUnsavedChanges } = useContext(UnsavedChangesContext);


  const { processHandleDrop, processHandleDelete, processHandleClickBlox, processHandleInsertSection } = useProcessHandlers();

  const { getAccessTokenSilently, user } = useAuth0();

  const { generateSvgs } = useContext(SvgServiceContext);
  const bloxSVGs = generateSvgs(processBloxes, isStack3D, SVGDisplayMode.RawSVG);
  
  useEffect(() => {
    if (!processId || processId === 'new' || processId === 'create') {
      setIsLoading(false);
      return;
    }
    
    const callReadResource = async () => {
      const baseURL = match.path.split('/:')[0];

      const token = baseURL.includes('process-viewer') ? '' : await getAccessTokenSilently();
      const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
      let fetchUrl = `${BASE_FABUBLOX_API_URL}/api/process/read/${processId}`;
      if (signature) {
        fetchUrl += `?signature=${signature}`;
      }
      if (processId) {
        fetch(fetchUrl, { headers })
          .then(res => res.json())
          .then(
            (result) => {
              if (result?.bloxes) {
                

                const isOriginalOwner = result.userId && result.userId === user?.sub;
                const isAdditionalOwner = ((result.additionalOwners ?? []) as AdditionalOwnerUser[]).some(owner => owner.userId === user?.sub);
                const readOnly = !(isOriginalOwner || isAdditionalOwner) || baseURL.includes('process-viewer');

                // backward comp
                if (result.bloxes[0].layers) {
                  result.bloxes[0].layers = result.bloxes[0].layers.map((layer: LayerProperties, index: number) => {
                    if (!layer.materialId) {
                      layer.materialId = uuidv4();
                    }
                    if (!layer.layerSimulationThickness) {
                      layer.layerSimulationThickness = index === 0 ? 1000 : 100;
                    }
                    if (!layer.layerSimulationThicknessUnit) {
                      layer.layerSimulationThicknessUnit = Units.NM;
                    }
                    return layer;
                  })
                }

                const defaultedBloxes = getBloxArrayWithDefaults(result.bloxes);

                // Not great that this has to happen first
                const tempSections = (result.sections ?? []) as Section[];
                setProcessSections(tempSections);
                const initialOpenSections: { [key: string]: boolean } = {};
                tempSections.forEach((section, sectionIndex) => {
                  initialOpenSections[section.sectionId] = sectionIndex !== 0;
                });
                setClosedSections(initialOpenSections);
                
                setProcessIsReadOnly(readOnly);
                setProcessBloxes(defaultedBloxes);
                setProcessName(result.processName);
                setProcessUsername(result.username);
                setProcessId(result.processId);
                setProcessIsPrivate(result.isPrivate);
                setProcessAdditionalOwners(result.additionalOwners ?? []);
                setProcessDesc(result.desc ?? '');
                setProcessReference(result.reference ?? '');
                setIsLoading(false);
                setProcessGroups(result.groups ?? []);
                setProcessGdsInfo(result.gdsInfo);
                setEditorUnsavedChanges(false);
              }
            },
            () => {
              clearProcess();
              history.push('/process-editor/new');
              showToast({
                message: "Failed to fetch process or you do not have access",
                intent: 'danger',
                timeout: 3000
              });
            }
          );
      }
    }

    callReadResource();

    return () => {
      clearProcess();
    }

  }, [clearProcess, processId, getAccessTokenSilently, user, match.path, signature, history]);

  const [scrollToSelectedBlox, setScrollToSelectedBlox] = useFabuState('scrollToSelectedBlox');
  useEffect(() => {
    if (!scrollToSelectedBlox) return;
    const sectionIdx = processSections.findIndex(section => section.bloxIds.includes(selectedBloxId));
    const section = processSections[sectionIdx];
    if (!section) return;
    setClosedSections(prevState => ({
      ...prevState,
      [section.sectionId]: false
    }));
    
    // Hack, use timeout to allow new scrollwidth to be set after drop.
    // There is a first setTimeout with no wait when calling setScrollToSelectedBlox
    // this second setTimeout is to allow the first to complete before scrolling
    setTimeout(() => scrollToSection(section.sectionId), 100);
    setScrollToSelectedBlox(false);

  }, [scrollToSelectedBlox, selectedBloxId, processSections, setScrollToSelectedBlox, closedSections, setClosedSections]);

  const { createSection } = useSectionManager();



  if (isLoading) {
    return <Loading />;
  }

  const scrollableContainerStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    margin: 'auto',
    overflowY: 'auto'
  };

  const getEmptySectionTarget = (sectionId: string, sectionIdx: number) => {

    // find the previous bloxId
    let lastBloxId: string | null = null;
    for (let i = sectionIdx - 1; i >= 0; i--) {
      const section = processSections[i];
      if (section && section.bloxIds.length > 0) {
        lastBloxId = section.bloxIds[section.bloxIds.length - 1];
        break; // Exit the loop once the last non-empty section's last bloxId is found.
      }
    }
    const bloxIdx = processBloxes.findIndex(blox => blox.id === lastBloxId);
    if (!lastBloxId) {
      return <></>;
    }
    return <BetweenBlox 
      copiedBlox={copiedBloxState[0]} 
      showDropTarget={true} 
      handleInsertBlox={processHandleDrop ? (item) => processHandleDrop(item, bloxIdx, sectionId) : undefined}
      handleInsertModule={processHandleInsertSection ? (item) => processHandleInsertSection(item.data, bloxIdx, sectionId, item.moduleId, item.moduleName) : undefined}
      />
  }

  const sectionHeaderProps = {
    processSections,
    setProcessSections,
    closedSections,
    setClosedSections
  };

  return <><div id={'sectionContainer'} style={{ ...scrollableContainerStyle }}>
  {processSections.map((section, sectionIndex) => (
    <Fragment key={section.sectionId}>
      <SectionHeader section={section} sectionIndex={sectionIndex} {...sectionHeaderProps} />
      {!closedSections[section.sectionId] && <Row id={section.sectionId} className="section" style={{height: `${processSections.length === 1 ? 'calc(100% - 40px)' : '400px'}`}}>
        {section.bloxIds.length > 0 ? section.bloxIds.map((bloxId, sectionBloxIdx) => {
          const bloxIndex = processBloxes.findIndex(b => b.id === bloxId);
          const blox = processBloxes[bloxIndex];
          if (!blox) return <></>;
          return (
            <ProcessBlox
              copiedBloxState={copiedBloxState}
              onClickBlox={() => processHandleClickBlox(blox.id)}
              processBloxes={processBloxes}
              bloxData={blox}
              bloxSVG={bloxIndex < bloxSVGs.length ? bloxSVGs[bloxIndex] : <></>}
              handleInsertBlox={processHandleDrop ? (item) => processHandleDrop(item, bloxIndex) : undefined}
              handleInsertModule={processHandleInsertSection ? (item: ModuleInsertItem) => processHandleInsertSection(item.data, bloxIndex, section.sectionId, item.moduleId, item.moduleName) : undefined}
              onDelete={processHandleDelete}
              handleSectionClick={createSection.bind(null, section.sectionId, blox.id)}
              hideStack={isStackHidden}
              key={blox.id + bloxIndex}
              showDropTarget={sectionBloxIdx === section.bloxIds.length - 1}
              leftMargin={sectionBloxIdx === 0}
              isSelected={selectedBloxId === blox.id}
            />
          );
        }) : (!closedSections[section.sectionId] && getEmptySectionTarget(section.sectionId, sectionIndex))}
      </Row>}
    </Fragment>
  ))}
</div>
</>
}