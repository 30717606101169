import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { PatternDialogContent} from "../../dialogs/PatternDialogContent";
import { lithographyDefaults, lithographyDisplayMap, LithoProperties } from "./litho-base";

export interface ScanningProbeLitho extends LithoProperties, BaseBlox {
    //EXPERIMENTAL

    // DISPLAY
    layerLabelsToImprint: string[] | null;
    percentToImprint: number | null;

    
    // SEMIFAB
    grayscalePattern: string | null;
    layerLabelsToExpose: string[] | null;
    imprintDepth: number | null;
    imprintDepthUnit: Units | null;
    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
    tipRadius: number | null;
    tipRadiusUnit: Units | null;
}

export const ScanningProbeLithoDisplay: DisplayMap = {
    
    // DISPLAY
    percentToImprint: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Probe Pattern Depth",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: 80,
        stepSize: 2
    },
    layerLabelsToImprint: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Pattern",
        order: 2,
        tabs: [Tab.DISPLAY,Tab.SEMIFAB],
    },
    // SEMIFAB
    // FUTURE RELEASE
    // maskMethod: {
    //     fieldType: DisplayFieldTypes.Dropdown,
    //     label: "Pattern Input Method",
    //     options: ["Ratios", "Coordinates", "GDS"],
    //     order: 1,
    //     tabs: [Tab.SEMIFAB],
    // },

    /* WILL BE AVAILABLE ON PAID VERSION ONLY
    grayscalePattern: {
        fieldType: DisplayFieldTypes.Input,
        label: "Define Grayscale Pattern",
        order: 0,
        tabs: [Tab.SEMIFAB],
        infoContent: GrayscalePatternDialogContent,
    },
    */

    /* TO DO remove
    layerLabelsToExpose: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Pattern",
        order: 4,
        materialFilter: "ONLY_RESIST",
        tabs: [Tab.SEMIFAB],
        infoContent: "Select which resists to pattern. Only layers deposited using a 'Spin Resist' step can be patterned."
    },
    */
    
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter pattern sidewall angle",
        isNumber: true,
        order: 6,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. For probe patterning positive values slope outwards and negative values to slope inwards."
    },
    /* WILL BE AVAILABLE ON PAID VERSION ONLY
    tipRadius: {
        fieldType: DisplayFieldTypes.Input,
        label: "Tip Radius",
        placeholder: "Enter the tip radius",
        isNumber: true,
        order: 6,
        units: [Units.ANG, Units.NM, Units.MICRON],
        tabs: [Tab.SEMIFAB],
    },
    */
    // TODO: Add DisplayFieldTypes.GDSLayers
    // TODO: Add DisplayFieldTypes.CoordinateInput
    imprintDepth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Probe Pattern Depth",
        placeholder: "Enter probe depth",
        isNumber: true,
        order: 5,
        units: [Units.ANG, Units.NM, Units.MICRON],
        tabs: [Tab.EXPERIMENTAL,Tab.SEMIFAB],
    },
    ...lithographyDisplayMap
}

export const getScanningProbeLitho = ({stepNumber}: BloxArgs): ScanningProbeLitho => ({
        ...lithographyDefaults,
        name: `Scanning Probe Lithography ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.ScanningProbeLitho,
        percentToImprint: 50,
        commentField: null,
        customFields: {},
        layerLabelsToImprint: [],
        // SEMIFAB
        // maskMethod: "Ratios",
        // gdsLayer: null,
        // coordPattern: null,
        // coordPatternUnit: Units.NM,
        grayscalePattern: "",
        layerLabelsToExpose: [],
        imprintDepth: 0,
        imprintDepthUnit: Units.NM,
        sidewallAngle: 45,
        sidewallAngleUnit: Units.DEG,
        tipRadius: 10,
        tipRadiusUnit: Units.NM,
});
