import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MultistepDialog, DialogStep, Callout, Intent, Spinner } from '@blueprintjs/core';
import CutlineRegionsStep from './CutlineRegionsStep';
import { useGetCells, useProcessCutLines } from '../../hooks/DataFetching/use-fetch-gds';
import { GdsInfo, Region, RegionsMap } from '../../__generated__/Gds';
import { useFabuState } from '../../hooks/state/use-fabu-state';
import UploadAndDefineParametersStep from './UploadAndDefineParametersStep';
import { showToast } from '../..';
import { updateGdsPattern } from '../../utils/gds/update-gds-pattern';
import { UnsavedChangesContext } from '../../hooks/state/unsaved-changes-provider';
import { Units } from '../../Data/enums';
import { BloxTypes } from '../../Data/BloxSchema/base-blox';
import { ConfirmDialog } from '../ConfirmDialog';
import { useConfirmDialog } from '../use-custom-comfirm-dialog';

interface UploadGdsDialogProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    savedGdsInfo?: GdsInfo;
}

const UploadGdsDialog: React.FC<UploadGdsDialogProps> = ({ isOpen, setIsOpen, savedGdsInfo }) => {
    const { setEditorUnsavedChanges } = useContext(UnsavedChangesContext);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedCell, setSelectedCell] = useState<string | null>(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [filePath, setFilePath] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [, setUploadProgress] = useState(0);
    const [, setProcessGdsInfo] = useFabuState('processGdsInfo');
    const [processBloxes, setProcessBloxes] = useFabuState('processBloxes');

    const { showConfirmDialog, ConfirmDialogElement } = useConfirmDialog(ConfirmDialog);

    // Cutline state
    const [cutlineName, setCutlineName] = useState<string | null>(null);
    const [x1, setX1] = useState<number | null>(null);
    const [y1, setY1] = useState<number | null>(null);
    const [x2, setX2] = useState<number | null>(null);
    const [y2, setY2] = useState<number | null>(null);

    const [regions, setRegions] = useState<{ [cutLineName: string]: { [layer: string]: Region[] } } | null>(null);
    const [regionsLoading, setRegionsLoading] = useState(false);

    // Hook to process cutlines
    const { mutate: processCutLines, error: regionsError } = useProcessCutLines();

    // Fetch cells after file upload
    const { data: cells, isLoading: cellsLoading, isSuccess: cellsSuccess, error: cellsError } = useGetCells((isFileUploaded && selectedFile) ? filePath : null);

    useEffect(() => {
        if (cellsLoading) {
            setUploadProgress(0.75);
        } else if (cellsSuccess) {
            setUploadProgress(1);
            setSelectedCell(null);
        }
    }, [cellsLoading, cellsSuccess]);

    // Initialize state from savedGdsInfo when dialog opens
    useEffect(() => {
        if (savedGdsInfo && !selectedFile) {
            setFilePath(savedGdsInfo.filePath);
            setIsFileUploaded(false); // the file hasn't just been uploaded by the user. it was uploaded previously
            setSelectedFile(null); // We don't have the actual File object
            const cutLineNames = Object.keys(savedGdsInfo.cutLines);
            if (cutLineNames.length > 0) {
                const firstCutLineName = cutLineNames[0];
                setCutlineName(firstCutLineName);
                const cutLineData = savedGdsInfo.cutLines[firstCutLineName];
                setX1(cutLineData.x1);
                setY1(cutLineData.y1);
                setX2(cutLineData.x2);
                setY2(cutLineData.y2);

                // For regionsMap, get the cellName
                const regionData = savedGdsInfo.regionsMap[firstCutLineName];
                setSelectedCell(regionData.cellName);
            }
        }
    }, [savedGdsInfo, selectedFile]);

    useEffect(() => {
        if (regionsError || cellsError || error) {
            showToast({
                message: regionsError?.message ?? cellsError?.message ?? error ?? 'An error occurred.',
                timeout: 5000,
                intent: 'danger',
            });
        }
    }, [regionsError, cellsError, error]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        setError(null);
        setRegions(null);
    }, [setIsOpen]);

    const handleProcessCutlines = useCallback(() => {
        setRegionsLoading(true);
        const cell = selectedCell ?? savedGdsInfo?.regionsMap[cutlineName ?? '']?.cellName;

        if (!cell || !filePath || x1 === null || y1 === null || x2 === null || y2 === null) {
            setError('Missing required data to process cutlines.');
            setRegionsLoading(false);
            return;
        }

        const finalCutlineName = (cutlineName === "" || cutlineName === null) ? 'Default Cutline' : cutlineName;

        const requestData = {
            filePath: filePath,
            cellName: cell,
            cutLines: {
                [finalCutlineName]: {
                    x1: x1,
                    y1: y1,
                    x2: x2,
                    y2: y2,
                },
            },
        };

        processCutLines(requestData, {
            onSuccess: (data) => {
                setCutlineName(finalCutlineName);
                setRegions(data);
                setRegionsLoading(false);
            },
            onError: () => {
                setRegionsLoading(false);
            },
        });
    }, [selectedCell, cutlineName, x1, y1, x2, y2, processCutLines, filePath, savedGdsInfo]);

    const handleStepChange = useCallback(
        (newDialogStepId: string, prevDialogStepId: string | undefined) => {
            if (prevDialogStepId === 'uploadAndDefine' && newDialogStepId === 'cutlineRegions') {
                handleProcessCutlines();
            }
        },
        [handleProcessCutlines]
    );

    const handleDone = useCallback(async () => {
        if (!selectedCell || !filePath || !cutlineName || x1 === null || y1 === null || x2 === null || y2 === null || !regions) {
            showToast({
                message: 'Missing required data to process GDS info.',
                intent: 'warning',
            });
            setError('Missing required data to process GDS info.');
            return;
        }
    
        const firstCutline = regions[cutlineName];
        const hasNoLayersInFirstCutline = firstCutline && Object.keys(firstCutline).length === 0;
        if (hasNoLayersInFirstCutline) {
            handleClose();
            return;
        }
    
        // Prepare the cutLines and regionsMap for GdsInfo
        const cutLines = {
            [cutlineName]: { x1: x1 as number, y1, x2, y2 },
        };
    
        const regionsMap: RegionsMap = {
            [cutlineName]: {
                cellName: selectedCell,
                layers: regions[cutlineName] || {},
            },
        };
    
        // Calculate the cutline length
        const cutlineLength = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    
        // Find the current substrateWidth in the StartBlox
        const startBloxIdx = processBloxes.findIndex((blox) => blox.bloxType === BloxTypes.StartBlox);
        const currentSubstrateWidth = startBloxIdx !== -1 ? processBloxes[startBloxIdx].substrateWidth : null;
        const currentSubstrateUnit = startBloxIdx !== -1 ? processBloxes[startBloxIdx].substrateWidthUnit : null;

        // Normalize substrate width to micrometers
        const normalizedSubstrateWidth =
            currentSubstrateUnit === Units.NM
                ? (currentSubstrateWidth ?? 0) / 1000 // Convert nanometers to micrometers
                : currentSubstrateWidth;

        // Precision threshold (0.001 micrometers)
        const epsilon = 0.001;

        // Check if the cutline length is different from the substrate width
        const isLengthDifferent =
            (normalizedSubstrateWidth === null || normalizedSubstrateWidth === undefined) || Math.abs(normalizedSubstrateWidth - cutlineLength) > epsilon;

        let shouldSetSimulationWidth = false;

        if (isLengthDifferent) {
            shouldSetSimulationWidth = await showConfirmDialog({
                content: (
                    <span>
                        The Simulation Width in the Simulate tab of your Substrate Stack will be updated from
                        <strong> {currentSubstrateWidth ?? ' N/A'} {currentSubstrateUnit ?? ''}</strong> to
                        <strong> {cutlineLength.toFixed(3)}</strong> micrometers.
                    </span>
                ),
                title: "Confirm GDS Updates",
            });
        }

        // Create GdsInfo object
        const gdsInfo: GdsInfo = {
            filePath: filePath,
            cellNames: isFileUploaded ? cells ?? [] : savedGdsInfo?.cellNames ?? [],
            userFileName: isFileUploaded ? selectedFile?.name ?? '' : savedGdsInfo?.userFileName ?? '',
            cutLines,
            regionsMap,
        };
    
        setSelectedFile(null);
        setProcessGdsInfo(gdsInfo);
    
        // Update processBloxes
        let processBloxesToUpdate = [...processBloxes];
    
        for (let idx = 0; idx < processBloxes.length; idx++) {
            const blox = processBloxes[idx];
    
            if (blox.gdsPattern && blox.gdsLayer) {
                let gdsLayer = blox.gdsLayer;
                const firstCutlineKey = Object.keys(gdsInfo.regionsMap)[0];
                const availableLayers = gdsInfo.regionsMap[firstCutlineKey].layers;
    
                // Check if the blox.gdsLayer exists in available layers
                if (!availableLayers[blox.gdsLayer]) {
                    gdsLayer = Object.keys(availableLayers)[0];
                }
    
                // Now that blox.gdsLayer is confirmed valid, update the GDS pattern
                processBloxesToUpdate = updateGdsPattern(gdsInfo, processBloxesToUpdate, idx, gdsLayer);
            }
        }
    
        if (startBloxIdx !== -1 && shouldSetSimulationWidth) {
            processBloxesToUpdate[startBloxIdx].substrateWidth = Number(cutlineLength.toFixed(3));
            processBloxesToUpdate[startBloxIdx].substrateWidthUnit = Units.MICRON;
        }
    
        setProcessBloxes(processBloxesToUpdate);
        setEditorUnsavedChanges(true);
        handleClose();
    }, [
        filePath,
        cutlineName,
        x1,
        y1,
        x2,
        y2,
        regions,
        selectedCell,
        setProcessGdsInfo,
        handleClose,
        selectedFile,
        cells,
        isFileUploaded,
        savedGdsInfo,
        processBloxes,
        setProcessBloxes,
        setEditorUnsavedChanges,
        showConfirmDialog,
    ]);
    
    return (
        <>
            {ConfirmDialogElement}
            <MultistepDialog
            isOpen={isOpen}
            onClose={handleClose}
            title={'Edit or Upload GDS Info'}
            style={{ minWidth: '600px' }}
            onChange={handleStepChange}
            finalButtonProps={{ intent: 'primary', text: 'Done', onClick: handleDone }}
        >
            {/* Step 1: Upload file and define parameters */}
            <DialogStep
                id="uploadAndDefine"
                title="Upload GDS File & Define Parameters"
                panel={
                    <UploadAndDefineParametersStep
                        cellsExtracted={!!cells}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        isFileUploaded={isFileUploaded}
                        setIsFileUploaded={setIsFileUploaded}
                        onUploadSuccess={setFilePath}
                        setError={setError}
                        selectedCell={selectedCell}
                        setSelectedCell={setSelectedCell}
                        cutlineName={cutlineName}
                        setCutlineName={setCutlineName}
                        x1={x1}
                        setX1={setX1}
                        y1={y1}
                        setY1={setY1}
                        x2={x2}
                        setX2={setX2}
                        y2={y2}
                        setY2={setY2}
                        cells={cells ?? []}
                        cellsLoading={cellsLoading}
                        filePath={filePath}
                        savedGdsInfo={savedGdsInfo}
                    />
                }
                nextButtonProps={{
                    disabled: (!selectedCell || selectedCell === "Select cell") || x1 === null || y1 === null || x2 === null || y2 === null || cellsLoading || (!!selectedFile && !isFileUploaded),
                }}
            />

            {/* Step 2: Cutline Regions */}
            <DialogStep
                id="cutlineRegions"
                title="Cutline Regions"
                panel={
                    regionsLoading ? (
                        <Spinner intent={Intent.PRIMARY} />
                    ) : regions ? (
                        <CutlineRegionsStep regions={regions} />
                    ) : (
                        <Callout intent={Intent.DANGER} title="Error">
                            {regionsError?.message ?? 'An error occurred while fetching cutline regions.'}
                        </Callout>
                    )
                }
                nextButtonProps={{ disabled: false }}
            />
        </MultistepDialog>
        </>
       
    );
};

export default UploadGdsDialog;
