import { BASE_FABUBLOX_API_URL } from '../../utils/constants';
import { useMutation, useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { showToast } from '../..'
import { Intent } from '@blueprintjs/core';
import { Region } from '../../__generated__/Gds';

export interface ProcessCutLinesRequest {
    filePath: string;
    cellName: string;
    cutLines: { [cutLineName: string]: { x1: number; y1: number; x2: number; y2: number } };
}

async function getCellsFromGDS(filePath: string, getAccessTokenSilently: () => Promise<string>): Promise<string[]> {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/gds/get-cells`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filePath }),
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch cells from GDS file at ${filePath}`);
    }
    return response.json();
}

export function useGetCells(filePath: string | null) {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery<string[], Error>(
        ['gdsCells', filePath],
        () => getCellsFromGDS(filePath!, getAccessTokenSilently),  // We know filePath is defined at this point
        {
            enabled: !!filePath,  // The query won't run if filePath is undefined
            onError: (error: Error) => {
                showToast({
                    message: error.message,
                    intent: Intent.DANGER,
                    timeout: 3000
                });
                console.error('Error fetching cells:', error);
            },
        }
    );
}

async function processCutLineRegions(requestData: ProcessCutLinesRequest, getAccessTokenSilently: () => Promise<string>): Promise<{ [cutLineName: string]: { [layer: string]: Array<Region> } }> {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/gds/process-cut-line-regions`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
    });

    if (!response.ok) {
        throw new Error(`Failed to process cut lines for cell ${requestData.cellName}`);
    }
    return response.json();
}

export function useProcessCutLines() {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation(
        (requestData: ProcessCutLinesRequest) => processCutLineRegions(requestData, getAccessTokenSilently),
        {
            onError: (error: Error) => {
                showToast({
                    message: error.message,
                    intent: Intent.DANGER,
                    timeout: 3000,
                });
                console.error('Error processing cut lines:', error);
            },
        }
    );
}
