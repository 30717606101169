import { Region } from "../../__generated__/Gds";
import { divideArrayByGCD } from "../../Services/PatternOps";

type Point = {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };

export function calculateEuclideanDistance(points: Point): number {
    const { x1, y1, x2, y2 } = points;
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Math.sqrt(dx * dx + dy * dy);
  }

export function mapGdsRegionsToPattern(
    totalLength: number,
    regions: Region[],
    scalingFactor = 1000
): string {
    // Sort boundaries by start position
    regions.sort((a, b) => a.start - b.start);

    // Merge overlapping boundaries
    const mergedRegions: Region[] = [];
    for (const region of regions) {
        if (mergedRegions.length === 0) {
            mergedRegions.push({ ...region });
        } else {
            const last = mergedRegions[mergedRegions.length - 1];
            if (region.start <= last.end) {
                // Overlapping boundaries, merge them
                last.end = Math.max(last.end, region.end);
            } else {
                mergedRegions.push({ ...region });
            }
        }
    }

    // segments is a list of regions and gaps
    let patternArray: number[] = [];
    let currentPosition = 0;

    if (mergedRegions.length === 0) {
        patternArray = [0];
    } else {
        if (mergedRegions[0].start > 0) {
            patternArray.push(0);
        }
    }

    for (const region of mergedRegions) {
        // Add gap before the boundary
        if (region.start > currentPosition) {
            patternArray.push(region.start - currentPosition);
        }

        // Add the boundary segment
        const boundaryLength = Math.min(region.end, totalLength) - region.start;
        if (boundaryLength > 0) {
            patternArray.push(boundaryLength);
        }

        currentPosition = Math.max(currentPosition, region.end);
    }

    // Add the remaining gap after the last boundary
    if (currentPosition < totalLength) {
        patternArray.push(totalLength - currentPosition);
    }

    // Scale and round the lengths
    const scaledUpArray = patternArray.map((x) => {
        let scaledLength = x * scalingFactor;
        // Round to the nearest integer
        scaledLength = Math.round(scaledLength);
        // Ensure the length is at least 1
        if (x > 0 && scaledLength < 1) {
            scaledLength = 1;
        }
        return scaledLength;
    });
    const scaledDownArray = divideArrayByGCD(scaledUpArray);

    // Join the lengths into a comma-separated string
    const relativeStringArray = scaledDownArray.join(',');

    return relativeStringArray;
}