import { useState } from "react";
import { BloxTypes } from "../../Data/BloxSchema/base-blox";
import { AdditionalOwnerUser, Section } from "../../__generated__/Process";
import {v4 as uuidv4} from 'uuid';
import { getNewBlox } from "../new-blox-data";
import { GdsInfo } from "../../__generated__/Gds";

export function useFabuStateProvider() {
    const startBlox = getNewBlox(BloxTypes.StartBlox, 1);
    return {
        // UI state
        selectedBloxIdState: useState(startBlox.id),
        scrollToSelectedBlox: useState(false),
        // PROCESS STATE
        processBloxes: useState([startBlox]),
        processName: useState(''),
        processUsername: useState(''),
        processId: useState(''),
        processDesc: useState(''),
        processReference: useState(''),
        processIsPrivate: useState(true),
        processAdditionalOwners: useState<AdditionalOwnerUser[]>([]),
        processIsReadOnly: useState(false),
        processGroups: useState<{id: string, groupName: string}[]>([]),
        processSections: useState<Section[]>([{
            sectionId: uuidv4(),
            bloxIds: [startBlox].map(blox => blox.id)
        }]),
        processGdsInfo: useState<undefined | GdsInfo>(undefined)
    }
}
