import { BASE_FABUBLOX_API_URL } from '../../utils/constants';
import { useMutation } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { showToast } from '../..';
import { Intent } from '@blueprintjs/core';

// Interfaces for the requests
interface GenerateUploadUrlRequest {
  processId: string;
}

interface RenameFileRequest {
  currentFilename: string;
  newFilename: string;
}

interface GenerateUploadUrlResponse {
  url: string;
  fields: any;
}

// Fetch function for generating the upload URL
async function generateUploadUrl(requestData: GenerateUploadUrlRequest, getAccessTokenSilently: () => Promise<string>): Promise<GenerateUploadUrlResponse> {
  const token = await getAccessTokenSilently();
  const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/storage/upload-url`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    throw new Error(`Failed to generate upload URL: ${response.statusText}`);
  }

  return response.json();
}

// Fetch function for renaming the file
async function renameFile(requestData: RenameFileRequest, getAccessTokenSilently: () => Promise<string>): Promise<{ message: string }> {
  const token = await getAccessTokenSilently();
  const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/storage/rename-file`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    throw new Error(`Failed to rename file: ${response.statusText}`);
  }

  return response.json();
}

// Custom hook for generating the upload URL
export function useGenerateUploadUrl() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    (requestData: GenerateUploadUrlRequest) => generateUploadUrl(requestData, getAccessTokenSilently),
    {
      onError: (error: Error) => {
        showToast({
          message: error.message,
          intent: Intent.DANGER,
          timeout: 3000,
        });
        console.error('Error generating upload URL:', error);
      },
    }
  );
}

// Custom hook for renaming the file
export function useRenameFile() {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation(
    (requestData: RenameFileRequest) => renameFile(requestData, getAccessTokenSilently),
    {
      onError: (error: Error) => {
        showToast({
          message: error.message,
          intent: Intent.DANGER,
          timeout: 3000,
        });
        console.error('Error renaming file:', error);
      },
    }
  );
}
