import { Color } from "../utils/Color";
import { AllBloxes, BloxTypes } from "./BloxSchema/base-blox";
import { ResistType } from "./BloxSchema/spin-coat-resist";
import { StackChange } from "./enums";
import { stackChangeMap } from "./stack-change-mappings";


export interface StackAction {
    id: string, //step number 0 indexed from bottom of stack
    type: StackChange,
    bloxID: string
}

export interface FillDeposit extends StackAction {
    color: Color,
    thickness:  number,
    resistType: ResistType, //figure out non-resist name
    label: string,
    materialId: string,
    isDopant: boolean
}

export interface ThinDeposit extends StackAction {
    color: Color,
    thicknessTop:  number,
    thicknessSide:  number,
    resistType: ResistType, 
    label: string,
    materialId: string,
    depositPattern: number[],
    invertPattern: boolean,

    // selective growth
    selectiveGrowth: boolean,
    materialTargets: string[],
}

export interface ThermalOxidation extends StackAction {
    color: Color,
    label: string,
    oxidationDepth: number,
    materialTargets: string[],
    materialId: string
}

export interface WaferBonding extends StackAction {
    color: Color,
    thickness:  number,
    label: string,
    materialId: string
}

export type Strip = StackAction

export interface Develop extends StackAction {
    materialTargets: string[]
}

export interface Etch extends StackAction {
    materialTargets: string[],
    etchPercentage: number,
    sidewaysEtchPercentage: number,
    isotropicEtch: boolean,
    keepFloatingStructures: boolean,
    etchBuriedLayers: boolean,
    etchPattern: number[],
    invertPattern: boolean
}

export interface StripResist extends StackAction {
    keepFloatingStructures: boolean,
}

export interface Clean extends StackAction {
    materialTargets: string[],
    keepFloatingStructures: boolean,
}

export interface Polish extends StackAction {
    polishPercentage: number,
}

export interface Pattern extends StackAction {
    resistType: ResistType,
    pattern: number[],
    invertPattern: boolean
}

export type Flip = StackAction

export interface IonImplantation extends StackAction {
    color: Color,
    thickness:  number,
    label: string,
    materialTargets: string[]
}

export interface DopingDiffusion extends StackAction {
    color: Color,
    inflate:  number,
    materialTargets: string[]
}

export function getStepAction(stackChange: StackChange, blox: AllBloxes, stackChangeIdx: number, materialLabelToIds: {[label: string] : string[]}): StackAction[] {
    // right now there is no validation on user inputs and everything
    // is returned as a string
    const uniqueId = `${blox.id}-${stackChangeIdx}`;
    if (stackChange === StackChange.MultiDeposit) {
        let deposits: FillDeposit[] = [];
        if (blox.layers) {
            deposits = blox.layers.map((layer, index) => {
                const colorObj = layer.layerColor ?? {R: 255, G: 255, B: 255, A: 1};
                const label = layer.layerLabel ?? "";
                
                return {
                    id: uniqueId,
                    type: StackChange.FillDeposit,
                    bloxID: blox.bloxType,
                    color: new Color(colorObj.R, colorObj.G, colorObj.B, colorObj.A),
                    thickness: layer.layerThickness ? parseInt(layer.layerThickness.toString()) : 10,
                    resistType: ResistType.NONE,
                    label: layer.showLabel !== false ? label : "", 
                    materialId: layer.materialId,
                    isDopant: false
                } as FillDeposit;
            })
        } else {
            // backward compatibility
            const colorObj = blox.layerColor ?? {R: 255, G: 255, B: 255, A: 1}
            return [{
                id: uniqueId,
                type: StackChange.FillDeposit,
                bloxID: blox.bloxType,
                color: new Color(colorObj.R, colorObj.G, colorObj.B, colorObj.A),
                thickness: blox.layerThickness ? parseInt(blox.layerThickness.toString()) : 10, //hacky hacky
                resistType: blox.resistType ?? ResistType.NONE,
                label: blox.layerLabel ?? "", 
                materialId: blox.materialId ?? blox.id,
                isDopant: false
            } as FillDeposit];
        }

        return deposits;
    } else if (stackChange === StackChange.FillDeposit) {
        const colorObj = blox.layerColor ?? {R: 255, G: 255, B: 255, A: 1}
        if (blox.fillGaps || blox.fillGaps === undefined || blox.fillGaps === null) {
            return [{
                id: uniqueId,
                type: StackChange.FillDeposit,
                bloxID: blox.bloxType,
                color: new Color(colorObj.R, colorObj.G, colorObj.B, colorObj.A),
                thickness: blox.layerThickness ? parseInt(blox.layerThickness.toString()) : 10, //hacky hacky
                resistType: blox.resistType ?? ResistType.NONE,
                label: blox.layerLabel ?? "", 
                materialId: blox.materialId ?? blox.id,
                isDopant : blox.bloxType === BloxTypes.SpinDopant
            } as FillDeposit];
        } else {
            const thicknessTop = blox.layerThickness ? parseInt(blox.layerThickness.toString()) : 10; //hacky hacky.
            const thicknessSide = Math.round(0.7 * thicknessTop);
            return [{
                id: uniqueId,
                type: StackChange.ThinDeposit,
                bloxID: blox.bloxType,
                color: new Color(colorObj.R, colorObj.G, colorObj.B, colorObj.A),
                thicknessTop: thicknessTop,
                thicknessSide: thicknessSide,
                resistType: blox.resistType ?? ResistType.NONE,
                label: blox.layerLabel ?? "",
                materialId: blox.materialId ?? blox.id,
                selectiveGrowth: false,
                materialTargets: [],
                depositPattern: [1],
                invertPattern: false
            } as ThinDeposit];
        }
        
    } else if (stackChange === StackChange.ThinDeposit) {
        const colorObj = blox.layerColor ?? {R: 255, G: 255, B: 255, A: 1};
        const thicknessTop = blox.layerThickness ? parseInt(blox.layerThickness.toString()) : 10; //hacky hacky.
        let thicknessSide = 0;
        if (blox.sidewallThickness !== null && blox.sidewallThickness !== undefined && blox.sidewallThickness > 0 && blox.sidewallThickness <= 100) {
            thicknessSide = Math.round((blox.sidewallThickness / 100) * thicknessTop);
        }
        const resistType = blox.bloxType === BloxTypes.HMDSVapor ? ResistType.ADHESION_PROMOTER : ResistType.NONE;

        let materialTargets: string[] = [];
        const labelsGrow = blox.layerLabelsToGrow;
        if (labelsGrow) {
            materialTargets = labelsGrow.flatMap(label => materialLabelToIds[label]);  
        }

        const depositPattern = blox.hasShadowMask ? convertPatternInput(blox.shadowMaskPattern) : [1];

        return [{
            id: uniqueId,
            type: StackChange.ThinDeposit,
            bloxID: blox.bloxType,
            color: new Color(colorObj.R, colorObj.G, colorObj.B, colorObj.A),
            thicknessTop: thicknessTop,
            thicknessSide: thicknessSide,
            resistType: resistType,
            label: blox.layerLabel ?? "",
            materialId: blox.materialId ?? blox.id,
            selectiveGrowth: blox.selectiveGrowth ?? false,
            materialTargets: materialTargets,
            depositPattern: depositPattern,
            invertPattern: blox.shadowMaskInvertPattern
        } as ThinDeposit];
    } else if (stackChange === StackChange.WaferBonding) {
        const colorObj = blox.layerColor ?? {R: 255, G: 255, B: 255, A: 1};
        const waferBondingStackAction = {
            id: uniqueId,
            type: StackChange.WaferBonding,
            bloxID: blox.bloxType,
            color: new Color(colorObj.R, colorObj.G, colorObj.B, colorObj.A),
            thickness: blox.layerThickness ? parseInt(blox.layerThickness.toString()) : 10, //hacky hacky
            label: blox.layerLabel ?? "", 
            materialId: blox.materialId ?? blox.id,
        } as WaferBonding;

        if (blox.useBondingAgent) {
            const bondingAgentColorObj = blox.bondingAgentColor ?? {R: 255, G: 255, B: 255, A: 1};
            let bondingAgentMaterialId = blox.materialId ?? blox.id;
            bondingAgentMaterialId += "-bonding-agent";

            if (blox.liquidBond) {
                const bondingAgentStackAction = {
                    id: uniqueId+"-bonding-agent",
                    type: StackChange.FillDeposit,
                    bloxID: blox.bloxType,
                    color: new Color(bondingAgentColorObj.R, bondingAgentColorObj.G, bondingAgentColorObj.B, bondingAgentColorObj.A),
                    thickness: blox.bondingLayerThickness ? parseInt(blox.bondingLayerThickness.toString()) : 10,
                    resistType: ResistType.NONE,
                    label: blox.bondingAgentLabel ?? "", 
                    materialId: bondingAgentMaterialId,
                    isDopant : false
                } as FillDeposit;
    
                return [bondingAgentStackAction, waferBondingStackAction] 
            } else {
                const bondingAgentStackAction = {
                    id: uniqueId+"-bonding-agent",
                    type: StackChange.WaferBonding,
                    bloxID: blox.bloxType,
                    color: new Color(bondingAgentColorObj.R, bondingAgentColorObj.G, bondingAgentColorObj.B, bondingAgentColorObj.A),
                    thickness: blox.bondingLayerThickness ? parseInt(blox.bondingLayerThickness.toString()) : 10,
                    resistType: ResistType.NONE,
                    label: blox.bondingAgentLabel ?? "", 
                    materialId: bondingAgentMaterialId,
                    isDopant : false
                } as WaferBonding;
    
                return [bondingAgentStackAction, waferBondingStackAction] 
            }
        } else {
            return [waferBondingStackAction];
        }

        
    } else if (stackChange === StackChange.Pattern) {
        if (blox.bloxType === BloxTypes.FloodExpose) {
            return [{
                id: uniqueId,
                type: StackChange.Pattern, 
                bloxID: blox.bloxType,
                pattern: [1],
                invertPattern: false
            } as Pattern];
        } else {
            const pattern = blox.patternType === 'GDS' ? blox.gdsPattern : blox.layerPattern;
            const isInverted = blox.patternType === 'GDS' ? blox.gdsInverted : blox.layerInvertPattern;
            return [{
                id: uniqueId,
                type: StackChange.Pattern, 
                bloxID: blox.bloxType,
                pattern: convertPatternInput(pattern),// [0, 2, 3, 2, 3, 2, 0]
                invertPattern: !!isInverted
            } as Pattern];
        }
        
    } else if (stackChange === StackChange.Imprint) {
        let materialTargets: string[] = [];

        const labelsToImprint = blox.layerLabelsToImprint;
        if (labelsToImprint) {
            materialTargets = labelsToImprint.flatMap(label => materialLabelToIds[label]);  
        }

        return [{
            id: uniqueId,
            type: StackChange.Etch,
            bloxID: blox.bloxType,
            materialTargets: materialTargets,
            etchPercentage: (blox.percentToImprint !== null && blox.percentToImprint !== undefined) ? blox.percentToImprint : 100,
            sidewaysEtchPercentage: 0,
            isotropicEtch: false,
            keepFloatingStructures: false,
            etchBuriedLayers: false,
            etchPattern: convertPatternInput(blox.layerPattern),
            invertPattern: blox.layerInvertPattern ?? false
        } as Etch];
    } else if (stackChange === StackChange.Etch) {
        let materialTargets: string[] = [];

        const labelsToEtch = blox.layerLabelsToEtch;
        if (labelsToEtch) {
            materialTargets = labelsToEtch.flatMap(label => materialLabelToIds[label]);  
        }

        return [{
            id: uniqueId,
            type: StackChange.Etch,
            bloxID: blox.bloxType,
            materialTargets: materialTargets,
            etchPercentage: (blox.percentToEtch !== null && blox.percentToEtch !== undefined) ? blox.percentToEtch : 100,
            sidewaysEtchPercentage: (blox.sidewaysEtch !== null && blox.sidewaysEtch !== undefined) ? blox.sidewaysEtch : 0,
            isotropicEtch: (blox.isotropicEtch !== null && blox.isotropicEtch !== undefined) ? blox.isotropicEtch : false,
            keepFloatingStructures: (blox.keepFloatingStructures !== null && blox.keepFloatingStructures !== undefined) ? blox.keepFloatingStructures : false,
            etchBuriedLayers: (blox.etchBuriedLayers !== null && blox.etchBuriedLayers !== undefined) ? blox.etchBuriedLayers : false,
            etchPattern: [0, 1, 0],
            invertPattern: false
        } as Etch];
    } else if (stackChange === StackChange.Clean)  {
        const labelsToClean = blox.layerLabelsToRemove;
        if (labelsToClean) {
            const materialTargets = labelsToClean.flatMap(label => materialLabelToIds[label]);  

            return [{
                id: uniqueId,
                type: StackChange.Clean,
                bloxID: blox.bloxType,
                materialTargets: materialTargets,
                keepFloatingStructures: (blox.keepFloatingStructures !== null && blox.keepFloatingStructures !== undefined) ? blox.keepFloatingStructures : false,
            } as Clean];
        } else {
            return [];
        }
    } else if (stackChange === StackChange.StripResist) {
        const stripResistStackAction = {
            id: uniqueId,
            type: stackChange,
            bloxID: blox.bloxType,
            keepFloatingStructures: (blox.keepFloatingStructures !== null && blox.keepFloatingStructures !== undefined) ? blox.keepFloatingStructures : false,
        } as StripResist

        const labelsToClean = blox.layerLabelsToRemove;
        if (labelsToClean) {
            const materialTargets = labelsToClean.flatMap(label => materialLabelToIds[label]);  

            const cleanStackAction = {
                id: uniqueId,
                type: StackChange.Clean,
                bloxID: blox.bloxType,
                materialTargets: materialTargets,
                keepFloatingStructures: (blox.keepFloatingStructures !== null && blox.keepFloatingStructures !== undefined) ? blox.keepFloatingStructures : false,
            } as Clean;

            return [cleanStackAction, stripResistStackAction];
        } else {
            return [stripResistStackAction];
        }

    } else if (stackChange === StackChange.Polish) {
        return [{
            id: uniqueId,
            type: StackChange.Polish,
            bloxID: blox.bloxType,
            polishPercentage: (blox.percentToPolish !== null && blox.percentToPolish !== undefined) ? blox.percentToPolish : 0,
        } as Polish];
    } else if (stackChange === StackChange.IonImplantation) {
        const colorObj = blox.layerColor ?? {R: 255, G: 255, B: 255, A: 1};
        const thickness = blox.layerThickness ? parseInt(blox.layerThickness.toString()) : 10; 
        
        let materialTargets: string[] = [];
        const labelsToImplant = blox.layerLabelsToImplant;
        if (labelsToImplant) {
            materialTargets = labelsToImplant.flatMap(label => materialLabelToIds[label]);  
        }

        return [{
            id: uniqueId,
            type: StackChange.IonImplantation,
            bloxID: blox.bloxType,
            color: new Color(colorObj.R, colorObj.G, colorObj.B, colorObj.A),
            thickness: thickness,
            label: blox.layerLabel ?? "",
            materialTargets: materialTargets
        } as IonImplantation];
    } else if (stackChange === StackChange.ThermalOxidation) {
        const colorObj = blox.layerColor ?? {R: 255, G: 255, B: 255, A: 1};

        let materialTargets: string[] = [];
        const labelsToOxidize = blox.layerLabelsToOxidize;
        if (labelsToOxidize) {
            materialTargets = labelsToOxidize.flatMap(label => materialLabelToIds[label]);  
        }

        return [{
            id: uniqueId,
            type: StackChange.ThermalOxidation,
            bloxID: blox.bloxType,
            color: new Color(colorObj.R, colorObj.G, colorObj.B, colorObj.A),
            oxidationDepth: (blox.oxidationDepth !== null && blox.oxidationDepth !== undefined) ? blox.oxidationDepth : 10,
            label: blox.layerLabel ?? "",
            materialTargets: materialTargets,
            materialId: blox.materialId ?? blox.id,
        } as ThermalOxidation];
    } else if (stackChange === StackChange.DopingDiffusion) {
        const colorObj = blox.layerColor ?? {R: 0, G: 0, B: 0, A: 0.25};
        const inflate = blox.layerThickness ? parseInt(blox.layerThickness.toString()) : 4;

        let materialTargets: string[] = [];
        const labelsToDiffuse = blox.layerLabelsToDiffuseIn;
        if (labelsToDiffuse) {
            materialTargets = labelsToDiffuse.flatMap(label => materialLabelToIds[label]);  
        }

        const dd: DopingDiffusion = {
            id: uniqueId,
            color: new Color(colorObj.R, colorObj.G, colorObj.B, colorObj.A),
            type: StackChange.DopingDiffusion,
            bloxID: blox.bloxType,
            inflate: inflate,
            materialTargets: materialTargets
        };

        if (blox.isOxidized) {
            const oxideColorObj = blox.oxidationLayerColor ?? {R: 255, G: 255, B: 255, A: 1};

            let oxideMaterialTargets: string[] = [];
            const labelsToOxidize = blox.layerLabelsToOxidize;
            if (labelsToOxidize) {
                oxideMaterialTargets = labelsToOxidize.flatMap(label => materialLabelToIds[label]);  
            }

            const thermalOxide: ThermalOxidation = {
                id: uniqueId+"-oxide",
                bloxID: blox.bloxType,
                type: StackChange.ThermalOxidation,
                color: new Color(oxideColorObj.R, oxideColorObj.G, oxideColorObj.B, oxideColorObj.A),
                oxidationDepth: (blox.oxidationDepth !== null && blox.oxidationDepth !== undefined) ? blox.oxidationDepth : 10,
                label: "",
                materialTargets: oxideMaterialTargets,
                materialId: blox.materialId ?? blox.id,
            };

            return [dd, thermalOxide];
        } else {
            return [dd];
        }
    } else if (stackChange === StackChange.Develop) {
        let materialTargets: string[] = [];
        const labelsToDevelop = blox.layerLabelsToDevelop;
        if (labelsToDevelop) {
            materialTargets = labelsToDevelop.flatMap(label => materialLabelToIds[label]);  
        }

        return [{
            id: uniqueId,
            type: stackChange,
            bloxID: blox.bloxType,
            materialTargets: materialTargets
        } as Develop];
    } else if (stackChange === StackChange.ImageReversalBake || stackChange === StackChange.HardBake || stackChange === StackChange.Flip || stackChange === StackChange.LiftOff || stackChange === StackChange.WetEtch) {
        return [{
            id: uniqueId,
            type: stackChange,
            bloxID: blox.bloxType
        } as StackAction];
    } else {
        throw new Error("Step type not implemented");
    }
}

export function getStackActionFromBloxes(bloxes: AllBloxes[], materialLabelToIds: {[label: string] : string[]}) {
    return bloxes.reduce((actionsSoFar: StackAction[], blox: AllBloxes) => {

        let stackChange = stackChangeMap[blox.bloxType];

        if (blox.isOxidized) {
            stackChange = [...stackChange, StackChange.ThermalOxidation]
        }

        if (blox.doubleSided) {
            stackChange = [...stackChange, StackChange.Flip, ...stackChange, StackChange.Flip];
        }

        if (blox.hardBake) {
            stackChange = [...stackChange, StackChange.HardBake]
        } else if (blox.imageReverse) {
            stackChange = [...stackChange, StackChange.ImageReversalBake]
        }

        if (!stackChange)
            return actionsSoFar;

        let stackActions = stackChange.filter(sC => sC !== StackChange.NotImplemented).map((
            stackChange: StackChange, stackChangeIdx: number) => getStepAction(stackChange, blox, stackChangeIdx, materialLabelToIds)
        ).flat();

        if (blox.environmentalDiffusion) {
            const fakeSpinDopantId = `${blox.id}-environmental`;
            const fakeSpinDopant: FillDeposit = {
                id: fakeSpinDopantId,
                type: StackChange.FillDeposit,
                bloxID: blox.bloxType,
                color: new Color(0, 0, 0, 1),
                thickness: 1,
                resistType: ResistType.NONE,
                label: "", 
                materialId: fakeSpinDopantId,
                isDopant : true
            };
            const removeFakeSpinDopantId = `${blox.id}-remove`;
            const removeFakeSpinDopant: Clean = {
                id: removeFakeSpinDopantId,
                type: StackChange.Clean,
                bloxID: blox.bloxType,
                materialTargets: [fakeSpinDopantId],
                keepFloatingStructures: true,
            };

            if (stackActions.length > 1) {
                // special case for doping diffusion+oxidation
                stackActions = [fakeSpinDopant, stackActions[0], removeFakeSpinDopant, stackActions[1]]
            } else {
                stackActions = [fakeSpinDopant, ...stackActions, removeFakeSpinDopant]
            }
        }

        return [...actionsSoFar, ...stackActions];
    }, [] as StackAction[]);
}

export function convertPatternInput(patternString : string | null | undefined): number[] {
    let patternArray: number[] = [1] // need to return non-null value if no input given otherwise entire layer gets removed in Display
    if (patternString) {
        patternArray = patternString.split(',').map(Number);
    }
    return patternArray
}

