import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { lithographyDefaults, lithographyDisplayMap, LithoProperties } from "./litho-base";

export interface InterferenceLitho extends LithoProperties, BaseBlox {
    //EXPERIMENTAL
    lightWavelength: string | null;
    expDose: number | null;
    expDoseUnit: Units | null;
    intensity: number | null;
    intensityUnit: Units | null;
    expTime: number | null;
    expTimeUnit: Units | null;

    // DISPLAY

    // SEMIFAB
    layerLabelsToExpose: string[] | null;
    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
}

export const interferenceLithoDisplay: DisplayMap = {
    lightWavelength: {
        fieldType: DisplayFieldTypes.Dropdown,
        label: "Light Wavelength",
        options: ["Select Wavelength","13.5nm (EUV)","193nm (DUV)","247nm (DUV)","365 nm (i-line)","375 nm", "405 nm (h-line)", "435 nm (g-line)"],
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    expDose: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure Dose",
        placeholder: "Enter exposure dose",
        isNumber: true,
        units: [Units.MILLIJOULPERSQCM,Units.UCOULPERSQCM],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    intensity: {
        fieldType: DisplayFieldTypes.Input,
        label: "Light intensity",
        placeholder: "Enter light intensity",
        isNumber: true,
        units: [Units.MWPERSQCM],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    expTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure time",
        placeholder: "Enter exposure time",
        isNumber: true,
        units: [Units.SEC,Units.MIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    
    // DISPLAY

    // SEMIFAB
    layerLabelsToExpose: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Expose",
        order: 0,
        materialFilter: "ONLY_RESIST",
        tabs: [Tab.SEMIFAB],
    },
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter etch sidewall angle",
        isNumber: true,
        order: 6,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. Use negative values to slope outwards, and positive values to slope inwards."
    },
    ...lithographyDisplayMap
}

export const getInterferenceLitho = ({stepNumber}: BloxArgs): InterferenceLitho => ({
        ...lithographyDefaults,
        name: `Interference Lithography ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.InterferenceLitho,
        lightWavelength: null,
        expDose: null,
        expDoseUnit: Units.MILLIJOULPERSQCM,
        intensity: null,
        intensityUnit: Units.MWPERSQCM,
        expTime: null,
        expTimeUnit: Units.SEC,
        layerPattern: "1,1,1,1,1,1,1,1,1,1,1",
        commentField: null,
        customFields: {},
        //SEMIFAB
        layerLabelsToExpose: [],
        sidewallAngle: 0,
        sidewallAngleUnit: Units.DEG,     
});
