import { Input, InputProps } from './Input';

export const NumericInput: React.FC<InputProps> = (props) => {
    return <Input
        {...props}
        inputStyle={{textAlign: 'right'}}
        warningText={"Invalid number format."}
        warningTitle={"Valid formats are: 100, 0.01, 1E2, 1e-2"}
        validation={(val: string) => !isNaN(Number(val))}
    />
}

